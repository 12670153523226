import React, { useContext } from 'react';
import * as Sentry from '@sentry/nextjs';
import { SeverityLevel } from '@sentry/nextjs';
import { Button, Img, PrimaryLayout, SEO } from '../components';
import { buildStaticProps } from '../ssg/not-found';
import { RateQuote, Sanity } from '../../types';
import { Context } from '../context';
import { getSEOFromCategory } from '../utils';
import { useRouter } from 'next/router';

class PageNotFoundError extends Error {
  data: {
    pathname: string;
  };

  constructor(pathname: string) {
    super('PageNotFound');
    this.name = `PageNotFoundError: ${pathname}`;
    this.data = {
      pathname,
    };
  }
}

export default function Custom404(props: any) {
  const ctx = useContext<RateQuote.AppState>(Context);
  const router = useRouter();

  React.useEffect(() => {
    const { pathname } = window.location
    const error = new PageNotFoundError(pathname)
    Sentry.captureException(error, {
      level: 'warning' as SeverityLevel,
      fingerprint: ['PageNotFoundError', pathname],
      contexts: {
        pageInfo: {
          pathname,
        },
      },
    })

    // Ensure analytics is defined and initialized
    if (typeof analytics !== 'undefined') {
      analytics.page('404')
    }
  }, [router])

  return (
    <PrimaryLayout>
      <SEO
        title={props.seoTitle || `Compare ${getSEOFromCategory(ctx.user?.category || '')}`}
        description={props.seoDescription || 'Page not found'}
      />
      <div className="relative min-h-screen">
        <div className="mx-auto flex w-full max-w-lg grow flex-col items-center justify-start overflow-hidden px-4 py-28 xl:pt-24">
          <div className="z-10">
            <h1 className="mb-8 text-center">{props.headline}</h1>
            <p className="mb-4">{props.subtitle}</p>
            <Button
              onClick={() => {
                router.push('/');
              }}
            >
              Back to Home
            </Button>
          </div>
          <Img
            classes="absolute sm:-left-20 bottom-0 sm:-bottom-20 md:h-2xl opacity-30"
            skeleton={{ classes: 'hidden' }}
            alt="white RateQuote logo with an aid symbol aside"
            src={props.backgroundImage}
          />
        </div>
      </div>
    </PrimaryLayout>
  );
}

export async function getStaticProps(): Promise<{
  props: Sanity.NotFound;
}> {
  return await buildStaticProps();
}
